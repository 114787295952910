<template>
  <div v-loading="isLoading">
    <can-i :permissions="[$options.ROLE_UNIT_ADMINISTRATION]">
      <div class="post-actions">
        <v-button @click="goToEditPost" icon="edit">{{ $t('page_post_view.btn_edit') }}</v-button>
        <el-popconfirm
            v-if="post.isPublished"
            class="popconfirm-btn"
            @confirm="unpublishPost"
            :confirm-button-text="$t('page_post_view.popover_unpublish.btn_confirm')"
            :cancel-button-text="$t('page_post_view.popover_unpublish.btn_cancel')"
            icon="el-icon-info"
            :title="$t('page_post_view.popover_unpublish.confirm_text')"
            icon-color="red"
        >
          <template #reference>
            <v-button icon="cancel_presentation">{{ $t('page_post_view.btn_unpublish') }}</v-button>
          </template>
        </el-popconfirm>
        <el-popconfirm
            v-else
            class="popconfirm-btn"
            @confirm="publishPost"
            :confirm-button-text="$t('page_post_view.popover_publish.btn_confirm')"
            :cancel-button-text="$t('page_post_view.popover_publish.btn_cancel')"
            icon="el-icon-info"
            :title="$t('page_post_view.popover_publish.confirm_text')"
            icon-color="red"
        >
          <template #reference>
            <v-button icon="present_to_all">{{ $t('page_post_view.btn_publish') }}</v-button>
          </template>
        </el-popconfirm>
        <el-popconfirm
            class="popconfirm-btn"
            @confirm="deletePost"
            :confirm-button-text="$t('page_post_view.popover_delete.btn_confirm')"
            :cancel-button-text="$t('page_post_view.popover_delete.btn_cancel')"
            icon="el-icon-info"
            :title="$t('page_post_view.popover_delete.confirm_text')"
            icon-color="red"
        >
          <template #reference>
            <v-button icon="delete">{{ $t('page_post_view.btn_delete') }}</v-button>
          </template>
        </el-popconfirm>
      </div>
    </can-i>
    <h2 class="heading">{{ post.title }}</h2>
    <div class="editor-artifact-content ql-editor" v-html="post.content" ></div>
  </div>
</template>

<script>
import 'quill/dist/quill.snow.css'

import {
  POST_EDIT_ROUTE_NAME,
  UNIT_POSTS_ROUTE_NAME
} from '../../../shared/constants/app-routes-names'

import {
  removePost,
  publishPost,
  unpublishPost
} from '../../../admin/posts/shared/services/post-service'
import {fetchPost} from '../../unit-page/services/unit-post-service'

import VButton from '../../../components/ui/v-button/VButton'
import CanI from '../../../auth/can-i/CanI'
import {ROLE_UNIT_ADMINISTRATION} from '../../../shared/constants/app-permissions'


export default {
  name: 'UnitPostView',
  ROLE_UNIT_ADMINISTRATION,
  components: {CanI, VButton},
  data() {
    return {
      post: {},
      isLoading: false
    }
  },
  created() {
    this.fetchPost()
  },
  methods: {
    fetchPost() {
      const { postID } = this.$route.params
      this.isLoading = true
      fetchPost(postID)
        .then((res) => {
          this.post = res
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    goToEditPost() {
      const { postID } = this.$route.params
      this.$router.push({
        name: POST_EDIT_ROUTE_NAME,
        params: {
          postID
        }
      })
    },
    publishPost() {
      const { postID } = this.$route.params
      publishPost(postID)
        .then(() => {
          this.post.isPublished = true
          this.$message({
            message: `${this.$t('page_post_view.messages.publish_success')}`,
            type: 'success'
          })
        })
      .catch(({response}) => {
        this.$message({
          message: `${this.$t('alerts.common_error', response.status)}`,
          type: 'error'
        })
      })
    },
    unpublishPost() {
      const { postID } = this.$route.params
      unpublishPost(postID)
          .then(() => {
            this.post.isPublished = false
            this.$message({
              message: `${this.$t('page_post_view.messages.unpublish_success')}`,
              type: 'success'
            })
          })
          .catch(({response}) => {
            this.$message({
              message: `${this.$t('alerts.common_error', response.status)}`,
              type: 'error'
            })
          })
    },
    deletePost() {
      const { postID, unitID } = this.$route.params
      removePost(postID)
        .then(() => {
          this.$router.push({
            name: UNIT_POSTS_ROUTE_NAME,
            params: {
              unitID
            }
          })
          this.isLoading = false
          this.$message({
            type: 'success',
            message: `${this.$t('page_post_view.messages.remove_success')}`
          })
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `${this.$t('page_post_view.messages.remove_error')}`
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }

}
</script>

<style scoped lang="scss">
.popconfirm-btn {
  margin-left: 1em;
}
</style>
